#go_widget .login {
    text-align: center;
}

#go_widget .login__brand {
  display: block;
  margin: 50px auto;
  width: 80%;
  max-width: 200px;
  height: auto;
}

#go_widget .login__error {
  color: #ff3a46;
}
