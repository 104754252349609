#go_widget *, #go_widget *:before, #go_widget *:after {
  box-sizing: border-box;
}

#go_widget {
  font-family: Circular, "Circular Std", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eeeeee;
  color: #2C3E50;
}

#go_widget .content {
  width: 100%;
  padding: 20px;
}

#go_widget .footer {
  margin-top: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.05);
}

#go_widget .DayPicker {
  width: 340px !important;
}

#go_widget .DayPickerNavigation {
  width: 320px !important;
}

#go_widget .DayPicker_transitionContainer {
  width: 340px !important;
}