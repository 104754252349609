#go_widget h1 {
  font-size: 18px;
  color: #2C3E50;
  font-weight: 700;
  margin: 0 auto 0.75em;
  line-height: 1.3em;
  max-width: 280px;
}

#go_widget h2 {
  font-size: 14px;
  font-weight: 600;
  color: #4D5B6A;
  letter-spacing: 0.03em;
  line-height: 1.425em;
  margin: 0 0 0.75em;
}

#go_widget p {
  font-size: 14px;
  color: #4D5B6A;
  letter-spacing: 0.03em;
  line-height: 1.425em;
  margin: 0 0 1em;
}

#go_widget p.narrow {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

#go_widget p.title {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 30px 0 15px;
  opacity: 0.5;
  overflow: hidden;
  text-align: left;
  display: block;
  position: relative;
}

#go_widget p.title:after {
  content: '';
  display: inline-block;
  border-top: 1px solid currentColor;
  height: 0;
  width: 100%;
  top: 8px;
  position: absolute;
  margin-left: 10px;
  opacity: 0.5;
}

#go_widget hr {
  border: none;
  border-top: 1px solid #2C3E50;
  opacity: 0.2;
  margin: 30px 0;
}

#go_widget .link {
  font-size: 12px;
  color: #B0B0B0;
  letter-spacing: 0.1em;
  text-decoration: none;
  padding-bottom: 5px;
  border-bottom: 1px solid #B0B0B0;
}

#go_widget .link svg {
  display: inline-block;
  height: 1em;
  width: auto;
  margin: 0 0.5em 0 0;
  position: relative;
  top: 0.15em;
}

#go_widget button {
  font-weight: bold !important;
}