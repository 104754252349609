#go_widget .app {
  width: 100%;
  height: 100%;
}

#go_widget .app__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

#go_widget .app__main {
  width: 100%;
  height: 100%;
}

#go_widget .app:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: #000000;
  opacity: 0;
  transition: opacity 0.3s 0.2s, height 0s 0.5s;
}

#go_widget .app--locked {
  position: absolute;
  top: 0;
  left: 0;
}

#go_widget .app--faded:after {
  height: 100%;
  opacity: 0.5;
  transition: opacity 0.3s, height 0s 0s;
}

#go_widget #app__positioner {
  display: block;
  width: 100%;
  height: 0;
}

#go_widget .mobile_qrcode {
  text-align: center;
}

#go_widget .mobile_qrcode #qrcode {
  display: block;
  margin: 20px auto 0;
  width: 100%;
  max-width: 120px;
  opacity: 0.8;
}

#go_widget .mobile_qrcode #qrcode img {
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 860px) {
  #go_widget .mobile_qrcode {
    display: none;
  }
}

body:focus {
  background-color: green;;
}