#go_widget .slide {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  transform: none;
  transition: transform 0.3s;
  padding-top: 74px;
}

#go_widget .slide:first-child {
  transform: none;
}

#go_widget .slide--done {
  transform: translateY(-100%);
}

#go_widget .slide--locked {
  overflow: hidden;
}

#go_widget .slide--done ~ .slide {
  transform: translateY(-100%);
}

