#go_widget .stats {
  width: 100%;
  padding: 10px;
  text-align: center;
  margin: 0 0 20px 0;
}

#go_widget .stats li {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #2C3E50;
  letter-spacing: 0.05em;
  text-align: center;
  line-height: 1.25em;
  padding: 0 25px;
  position: relative;
}

#go_widget .stats li:before {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  width: 1px;
  background: #2C3E50;
  opacity: 0.2;
  height: 20px;
}

#go_widget .stats li:first-child:before {
  display: none;
}

#go_widget .stats li b {
  font-size: 16px;
  color: #2C3E50;
  letter-spacing: 0.02em;
  font-weight: 700;
  display: block;
  margin: 6px 0 0 0;
}