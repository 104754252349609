#go_widget .order__item {
  background: #ffffff;
  box-shadow: 0 5px 24px -6px rgba(44, 62, 80, 0.25);
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
}

#go_widget .order__item__status > div {
  width: 100%;
  justify-content: left;
}

#go_widget .order__item__name {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0;
  padding: 0 10px;
  margin: 20px 0 0;
}

#go_widget .order__item__route {
  padding: 0 15px;
  margin: 10px 0 0;
}

#go_widget .order__item__info {
  padding: 0 5px;
  margin: 5px 0 0;
}

#go_widget .order__item__info__item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  line-height: 0;
}

#go_widget .order__item__action {
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 5px 5px;
  margin: 10px 0 0;
}
