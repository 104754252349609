@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-book.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-book.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-bookitalic.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-bookitalic.woff) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-medium.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-medium.woff) format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-mediumitalic.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-mediumitalic.woff) format("woff");
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-bold.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-bold.woff) format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-bolditalic.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-bolditalic.woff) format("woff");
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-black.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-black.woff) format("woff");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: Circular;
    src: url(https://web.go-fleet.ch/static/fonts/Circular/circular-blackitalic.woff2) format("woff2"), url(https://web.go-fleet.ch/static/fonts/Circular/circular-blackitalic.woff) format("woff");
    font-weight: 900;
    font-style: italic
}