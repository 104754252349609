#go_widget fieldset {
  padding: 5px;
  margin-bottom: 20px;
  background: #FFFFFF;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.20), 0 0 30px 0 rgba(255, 255, 255, 0.20);
  border-radius: 5px;
  font-size: 0;
  border: none;
}

#go_widget .radio {
  box-shadow: none;
  border: 1px solid rgb(202, 207, 211);
  padding: 21px;
}

#go_widget .radio--active {
  border-color: #00A7E7;
  box-shadow: rgba(44, 62, 80, 0.25) 0px 5px 24px -6px;
}

#go_widget .radio--active div:not(:last-of-type) {
  color: #00A7E7;
}

#go_widget fieldset .spacer {
  height: 20px;
}

#go_widget .form_row {
  display: inline-block;
  width: 100%;
  padding: 5px;
  text-align: left;
}

#go_widget .form_row > label[for^="type"] {
  height: 60px !important;
}

#go_widget .form_row--center {
  text-align: center;
}

#go_widget .form_row--split {
  width: 50%;
  vertical-align: top;
}

#go_widget .legal {
  margin-top: 20px;
  font-size: 14px;
  opacity: 0.5;
}

#go_widget .legal a {
  color: inherit;
}

#go_widget .extendable_field {
  margin: -10px;
  background: #eeeeee;
  border-radius: 5px;
  overflow: hidden;
  height: 80px;
  transition: top 0.3s 0.2s, height 0.3s 0.0s, box-shadow 0.3s;
}

#go_widget .extendable_field--extended {
  box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.20);
  top: 30px !important;
  height: calc(100% - 40px);
  transition: top 0.3s 0.0s, height 0.3s 0.2s, box-shadow 0.3s;
}

#go_widget .extendable_field__dummy {
  height: 60px;
  width: 100%;
}

#go_widget .extendable_field__input {
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.20), 0 0 30px 0 rgba(255, 255, 255, 0.20);
  border-radius: 5px;
}

#go_widget .extendable_field__input svg {
  color: rgb(44, 62, 80);
}

#go_widget .extendable_field__list {
  padding: 10px 10px 40vh 10px;
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: -1;
}

#go_widget .extendable_field__list li {
  margin: 10px 0;
}

#go_widget .extendable_field__list li a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  background: #FFFFFF;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.20), 0 0 30px 0 rgba(255, 255, 255, 0.20);
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  color: #2C3E50;
  letter-spacing: 0;
  line-height: 1.5em;
}

#go_widget .extendable_field__list li a.focus {
  border: 1px solid #2C3E50;
}

#go_widget .extendable_field__list li b {
  font-weight: 700;
}

#go_widget .code_input__frame {
  display: block;
  margin: 0 -30px;
}

#go_widget .code_input {
  position: relative;
  font-size: 0;
  width: 340px;
  padding: 0 30px;
  display: block;
  overflow: hidden;
  margin: 30px auto 15px;
}

#go_widget .code_input input {
  font-family: monospace;
  display: block;
  font-weight: 700;
  height: 100%;
  width: 150%;
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  border: none;
  -webkit-appearance: none;
  font-size: 24px;
  letter-spacing: 32px;
  text-indent: 46px;
  outline: none;
}

#go_widget .code_input span {
  display: inline-block;
  width: 14.66666%;
  margin: 0 1%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.20);
  border-radius: 5px;
  padding: 1px;
}

#go_widget .code_input span:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 4px;
}

#go_widget .code_input__status {
  margin-bottom: 30px;
}

#go_widget .code_input--error span {
  background: linear-gradient(to bottom, #FF6C7C 0%, #FF3A46 100%);
}

#go_widget .code_input--error + .code_input__status {
  color: #FF3A46;
}

#go_widget .code_input--success span {
  background: linear-gradient(to bottom, #00DDC3 0%, #00B990 100%);
}

#go_widget .rating {
  width: 80px;
  height: 80px;
  background: linear-gradient(to bottom, #00DDC3 0%, #00B990 100%);
  box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.20);
  border: none;
  -webkit-appearance: none;
  display: inline-block;
  margin: 0 10px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 22px;
  line-height: 85px;
}

#go_widget .rating svg {
  display: inline-block;
  width: 1em;
  height: 1em;
}

#go_widget .rating--bad {
  background: linear-gradient(to bottom, #FF6C7C 0%, #FF3A46 100%);
}