#go_widget .widget {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  transition: background 0.3s, height 0s 0.3s;
}

#go_widget .widget--open {
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: background 0.3s, height 0s 0s;
}

#go_widget .widget--open .widget__frame {
  opacity: 1;
  transform: none;
}

#go_widget .widget--open .widget__content {
  transform: none;
}

#go_widget .widget__close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#go_widget .widget__frame {
  padding: 90px 30px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translateY(10%) scale(0.9);
  transition: opacity 0.3s, transform 0.3s;
}

#go_widget .widget__main {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 540px;
  background-color: #eeeeee;
  background-size: cover;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
  text-align: right;
}

#go_widget .widget__content {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(100%);
  position: relative;
  background: #eeeeee;
}

#go_widget .widget__header {
  height: 74px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  padding: 15px 30px 15px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
  text-align: justify;
  font-size: 0;
  z-index: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#go_widget .widget__header:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 0;
}

#go_widget .widget__header__navigation {
  display: inline-block;
  vertical-align: middle;
}

#go_widget .widget__header__navigation li {
  display: inline-block;
  margin: 0 0 0 20px;
}

#go_widget .widget__header__navigation li a {
  display: block;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  border-bottom: 2px solid transparent;
  line-height: 20px;
  padding: 5px 0;
  opacity: 0.4;
  cursor: pointer;
}

#go_widget .widget__header__navigation li a.active {
  border-bottom-color: currentColor;
  opacity: 1;
}

#go_widget .widget__header__brand {
  display: inline-block;
  vertical-align: middle;
  height: 44px;
  max-width: 100px;
  object-fit: contain;
  width: auto;
}

#go_widget .widget__header__brand--minimal {
  display: block;
  margin: 0 auto;
}

#go_widget .widget__header__close {
  display: inline-block;
  width: 44px;
  height: 44px;
  font-size: 28px;
  text-align: center;
  line-height: 52px;
  color: #2c3e50;
  vertical-align: top;
}

#go_widget .widget--fms .widget__header {
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#go_widget .widget--fms .widget__content {
  padding-top: 74px;
}

#go_widget .widget--fms .slide {
  display: none;
}

#go_widget .widget--fms iframe {
  display: block;
  border: none;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 860px) {
  #go_widget .widget__frame {
    padding: 0;
  }

  #go_widget .widget__main {
    border-radius: 0;
    background-image: none;
  }

  #go_widget .widget__header {
    border-radius: 0;
  }

  #go_widget .widget__content {
    max-width: 100%;
  }
}
