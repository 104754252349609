#go_widget .waypoints__reverse {
  display: block;
  outline: none;
  background: #D4D8DE;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  -webkit-appearance: none;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  right: 70px;
  margin: -22px -22px 0 0;
  padding: 2px;
  border: none;
  transform: rotate(0deg);
}

#go_widget .waypoints__reverse--reversed {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

#go_widget .waypoints__reverse svg {
  display: block;
  width: 100%;
  height: 100%;
}