#go_widget .card {
  background: #FFFFFF;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.20), 0 0 30px 0 rgba(255, 255, 255, 0.20);
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  display: block;
}

#go_widget .card__icon {
  font-size: 75px;
}

#go_widget .card__section {
  margin: 30px 0;
  text-align: left;
}

#go_widget .card__section__title {
  font-size: 12px;
  color: #2C3E50;
  letter-spacing: 0.05em;
  line-height: 1.25em;
  margin: 0 0 0.75em 0;
  text-align: center;
}

#go_widget .card__section__title__icon {
  display: inline-block;
  font-size: 22px;
  vertical-align: middle;
  margin: 0 5px 0 0;
  color: #2C3E50;
}

#go_widget .card__section__content {
  background: #FAFAFA;
  border-radius: 5px;
  padding: 0 20px;
  font-weight: 500;
}

#go_widget .card__section__text {
  padding: 15px 0;
  text-align: center;
}

#go_widget .card__section__text span {
  display: block;
  font-size: 12px;
  letter-spacing: 0.05em;
  margin: 10px 0 0 0;
  font-weight: 400;
}